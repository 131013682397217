export default {
    nav: {
        home: 'Home',
        login: 'Login',
        reset_password: 'Reset Password',
        items: 'Items',
        categories: 'Categories',
        locations: 'Locations',
        employees: 'Employees',
        suppliers: 'Suppliers',
        purchasers: 'Purchasers',
        roles_permissions: 'Roles & Permissions',
        users: 'Users',
        profile: 'Profile',
        logout: 'Logout',
        signup: 'Sign Up',
    },
    profile: {
        profile: 'profile',
        user_account: 'User Account',
        personal_information: 'Personal Information',
        address_information: 'Address Information',
        change_password: 'Change Password',
        success_updated: 'Your Account has been updated',
        error_update: 'Failed to update profile'
    },
    items: {
        items: 'items',
        id: 'ID',
        add_item: 'Add Item',
        edit_item: 'Edit Item',
        search_items: 'Search items',
        details: 'Details',
        financial_breakdown: 'Financial Breakdown',
        full_code: 'Full Code',
        code: 'Code',
        name: 'Name',
        depreciation: 'Depreciation',
        description: 'Description',
        unit_price: 'Unit Price (EUR)',
        financial_year: 'Financial Year',
        order_no: 'Order No.',
        order_number: 'Order Number',
        invoice_date: 'Invoice Date',
        invoice_no: 'Invoice No.',
        invoice_number: 'Invoice Number',
        supplier: 'Supplier',
        purchased_by: 'Purchased by',
        purchaser: 'Purchaser',
        category: 'Category',
        serial_no: 'Serial No.',
        serial_number: 'Serial Number',
        sequence_no: 'Sequence No.',
        sequence_number: 'Sequence Number',
        depreciated_year: 'Depreciated Year',
        found: 'Found',
        is_found: 'Is Found',
        category_no: 'Category No.',
        category_number: 'Category Number',
        location: 'Location',
        assigned_to: 'Assigned to',
        type: 'Type',
        notes: 'Notes',
        note: 'Notes',
        item: 'Item',
        actions: 'Actions',
        depreciated: 'Depreciated',
        depreciation_in_years: 'Depreciated in Years',
        depreciation_in_months: 'Depreciated in Months',
        total_depreciation: 'Total Depreciation',
        balance_carried_forward: 'Balance Carried Forward',
        write_off: 'Write Off',
        wo: 'W/O',
        written_off: 'Written Off',
        written_off_at: 'Written Off At',
        wo_authority: 'W/O Authority',
        written_off_authority: 'W/O Authority',
        item_written_off: 'Item Written Off',
        dispose_of: 'Dispose Of',
        disposed_of: 'Disposed Of',
        disposed_of_at: 'Disposed Of At',
        dispose: 'Dispose',
        active: 'Active',
        pending: 'Pending',
        new_values: 'New Values',
        old_values: 'Old Values',
        supplier_id: 'Supplier ID',
        purchaser_id: 'Purchaser ID',
        location_id: 'Location ID',
        category_id: 'Category ID',
        type_id: 'Type ID',
        employee_id: 'Employee ID',
        status: 'Status',
        filter_by: 'Filter by',
        prompt_dispose_of: 'Are you sure you want to dispose of this item?',
        prompt_delete: 'Are you sure you want to delete this item?',
        success_created: 'Item has been created',
        success_updated: 'Item has been updated',
        error_create: 'Failed to create item',
        error_update: 'Failed to update item',
        error_delete: 'Failed to delete item',
        error_retrieve: 'Failed to retrieve items',
        error_retrieve_suppliers: 'Failed to retrieve suppliers',
        error_retrieve_purchasers: 'Failed to retrieve purchasers',
        error_retrieve_categories: 'Failed to retrieve categories',
        error_retrieve_locations: 'Failed to retrieve locations',
        error_retrieve_users: 'Failed to retrieve users',
        error_retrieve_employees: 'Failed to retrieve employees',
        error_retrieve_types: 'Failed to retrieve types',
        error_retrieve_item: 'Failed to retrieve item',
        error_retrieve_audits: 'Failed to retrieve audits',
        error_write_off: 'Failed to write off item',
        item_details: 'Item Details',
        update_item: 'Update Item',
        delete_item: 'Delete Item',
    },
    categories: {
        categories: 'categories',
        add_category: 'Add Category',
        edit_category: 'Edit Category',
        search_categories: 'Search categories',
        code: 'Code',
        name: 'Name',
        depreciation_value: 'Depreciation Value (%)',
        category: 'Category',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this category?',
        success_created: 'Category has been created',
        success_updated: 'Category has been updated',
        error_create: 'Failed to create category',
        error_update: 'Failed to update category',
        error_delete: 'Failed to delete category',
        error_retrieve: 'Failed to retrieve categories',
        category_details: 'Category Details',
        update_category: 'Update Category',
        delete_category: 'Delete Category',
    },
    types: {
        types: 'types',
        add_type: 'Add Type',
        edit_type: 'Edit Type',
        search_types: 'Search types',
        name: 'Name',
        category: 'Category',
        type: 'Type',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this type?',
        success_created: 'Type has been created',
        success_updated: 'Type has been updated',
        error_create: 'Failed to create type',
        error_update: 'Failed to update type',
        error_delete: 'Failed to delete type',
        error_retrieve: 'Failed to retrieve types',
        type_details: 'Type Details',
        update_type: 'Update Type',
        delete_type: 'Delete Type',
    },
    locations: {
        locations: 'locations',
        add_location: 'Add Location',
        edit_location: 'Edit Location',
        search_locations: 'Search locations',
        name: 'Name',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this location?',
        success_created: 'Location has been created',
        success_updated: 'Location has been updated',
        error_create: 'Failed to create location',
        error_update: 'Failed to update location',
        error_delete: 'Failed to delete location',
        error_retrieve: 'Failed to retrieve locations',
        location_details: 'Location Details',
        update_location: 'Update Location',
        delete_location: 'Delete Location',
    },
    employees: {
        employees: 'employees',
        add_employee: 'Add Employee',
        edit_employee: 'Edit Employee',
        search_employees: 'Search employees',
        name: 'Name',
        email: 'Email',
        email_address: 'Email Address',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this employee?',
        success_created: 'Employee has been created',
        success_updated: 'Employee has been updated',
        error_create: 'Failed to create employee',
        error_update: 'Failed to update employee',
        error_delete: 'Failed to delete employee',
        error_retrieve: 'Failed to retrieve employees',
        employee_details: 'Employee Details',
        update_employee: 'Update Employee',
        delete_employee: 'Delete Employee',
    },
    suppliers: {
        suppliers: 'suppliers',
        add_supplier: 'Add Supplier',
        edit_supplier: 'Edit Supplier',
        search_suppliers: 'Search suppliers',
        name: 'Name',
        phone: 'Phone',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this supplier?',
        success_created: 'Supplier has been created',
        success_updated: 'Supplier has been updated',
        error_create: 'Failed to create supplier',
        error_update: 'Failed to update supplier',
        error_delete: 'Failed to delete supplier',
        error_retrieve: 'Failed to retrieve suppliers',
        supplier_details: 'Supplier Details',
        update_supplier: 'Update Supplier',
        delete_supplier: 'Delete Supplier',
    },
    purchasers: {
        purchasers: 'purchasers',
        add_purchaser: 'Add Purchaser',
        edit_purchaser: 'Edit Purchaser',
        search_purchasers: 'Search purchasers',
        name: 'Name',
        phone: 'Phone',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this purchaser?',
        success_created: 'Purchaser has been created',
        success_updated: 'Purchaser has been updated',
        error_create: 'Failed to create purchaser',
        error_update: 'Failed to update purchaser',
        error_delete: 'Failed to delete purchaser',
        error_retrieve: 'Failed to retrieve purchasers',
        purchaser_details: 'Purchaser Details',
        update_purchaser: 'Update Purchaser',
        delete_purchaser: 'Delete Purchaser',
    },
    audits: {
        audits: 'Audits',
        audit: 'Audit',
        user: 'User',
        event: 'Event',
        detail: 'Detail',
        date_time: 'Date & Time',
    },
    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_created: 'role has been created',
        success_updated: 'role has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search users',
        name: 'Name',
        email: 'Email',
        email_address: 'Email Address',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this user?',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
        user_details: 'User Details',
        update_user: 'Update User',
        delete_user: 'Delete User',
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
        passwords_must_match: 'Passwords must match',
        password_must_be_atleast_x_characters: 'Password must be at least {x} characters',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        setup_your_account: 'Setup your account.',
        account_created: 'Account successfully created',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        confirmation_password_must_match: 'Confirmation password must match',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password!',
        failed_signup: 'Signup Failed',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    start: 'Start',
    finish: 'Finish',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    print: 'Print',
    send: 'Send',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    actions: 'Actions',
    back: 'Back',
    submit: 'Submit',
    assign: 'Assign',
    okay: 'Okay',
    signup: 'Sign Up',
}
