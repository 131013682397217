<template>
    <div class="mobile-menu-container">
        <router-link :to="{name: 'items-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read items')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'box']"/>
            </div>
            <p>{{$t('nav.items')}}</p>
        </router-link>

        <router-link :to="{name: 'locations-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read locations')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'map-marker']"/>
            </div>
            <p>{{$t('nav.locations')}}</p>
        </router-link>

        <router-link :to="{name: 'employees-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read employees')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'user-crown']"/>
            </div>
            <p>{{$t('nav.employees')}}</p>
        </router-link>

        <router-link :to="{name: 'suppliers-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read suppliers')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'person-carry']"/>
            </div>
            <p>{{$t('nav.suppliers')}}</p>
        </router-link>

        <router-link :to="{name: 'purchasers-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read purchasers')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'user-check']"/>
            </div>
            <p>{{$t('nav.purchasers')}}</p>
        </router-link>

        <router-link :to="{name: 'roles-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read roles')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{$t('nav.roles_permissions')}}</p>
        </router-link>

        <router-link :to="{name: 'users-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read users')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{$t('nav.users')}}</p>
        </router-link>

    </div>
</template>

<script>
export default {
    name: "MobileMenu"
}
</script>

<style lang="scss" scoped>
.mobile-menu-container {
    @apply flex-1 grid grid-cols-2 content-start gap-y-12 gap-x-8 h-auto p-8 bg-white;

    @screen sm {
        @apply grid-cols-3;
    }

    @screen md {
        @apply hidden;
    }

    .nav-item {
        @apply flex flex-col items-center;

        .icon-container {
            @apply p-2 rounded-lg border-2 border-grey-light flex flex-col items-center justify-center;
            height: 40px;
            width: 40px;

            svg {
                @apply text-black w-auto;

                height: 18px;
            }
        }

        p {
            @apply text-black text-sm font-bold mt-4 text-center;
        }

        &:hover, &:focus {
            .icon-container {
                @apply border-secondary;
            }
        }

        &.router-link-active {
            .icon-container {
                @apply bg-secondary border-secondary;

                svg {
                    @apply text-white;
                }
            }
        }
    }
}
</style>