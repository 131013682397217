<template>
    <div class="items-create-modal">
        <ModalContainer :title="$t('items.add_item')" identifier="items-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.item.description.$model" identifier="description" :label="$t('items.description')"
                               :disabled="is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item" :has-error="$v.item.description.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.item.description.required">
                            {{$t('validation.x_is_required',{x: $t('items.description')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormGroupFour>
                    <FormInputSelect v-if="$store.getters.hasRole('finance administrator')" v-model="$v.item.found.$model"
                                     identifier="found" :label="$t('items.found')" :options="found_options"
                                     :disabled="is_saving || is_loading_original_item" :has-error="$v.item.found.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.item.found.required">
                                {{ $t('validation.x_is_required', {x: $t('items.found')}) }}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputText v-model="$v.item.unit_price.$model" identifier="unit_price" :label="$t('items.unit_price')"
                                   :disabled="is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item" :has-error="$v.item.unit_price.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.item.unit_price.required">
                                {{$t('validation.x_is_required',{x: $t('items.unit_price')})}}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputSelect v-model="$v.item.financial_year.$model" identifier="financial_year"
                                     :label="$t('items.financial_year')" :options="year_options"
                                     :disabled="is_loading_years || is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item" :has-error="$v.item.financial_year.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.item.financial_year.required">
                                {{ $t('validation.x_is_required', {x: $t('items.financial_year')}) }}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputText v-model="$v.item.order_number.$model" identifier="order_number" :label="$t('items.order_no')"
                                   :disabled="is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item" :has-error="$v.item.order_number.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.item.order_number.required">
                                {{$t('validation.x_is_required',{x: $t('items.order_no')})}}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputDateTime v-model="$v.item.invoice_date.$model" identifier="date" :label="$t('items.invoice_date')"
                                       :disabled="is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item"
                                       position="bottom" :onlyDate="true" formatted="DD/MM/YYYY" :has-error="$v.item.invoice_date.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.item.invoice_date.required">
                                {{ $t('validation.x_is_required', {x: $t('items.invoice_date')}) }}
                            </p>
                        </template>
                    </FormInputDateTime>

                    <FormInputText v-model="$v.item.invoice_number.$model" identifier="invoice_number" :label="$t('items.invoice_no')"
                                   :disabled="is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item" :has-error="$v.item.invoice_number.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.item.invoice_number.required">
                                {{$t('validation.x_is_required',{x: $t('items.invoice_no')})}}
                            </p>
                        </template>
                    </FormInputText>

                    <div class="multiselect-button-wrapper">
                        <FormInputSelect v-model="$v.item.supplier.$model" identifier="supplier"
                                         :label="$t('items.supplier')" :options="supplier_options"
                                         :disabled="is_loading_suppliers || is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item"
                                         :has-error="$v.item.supplier.$error" track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                            <template v-slot:errors>
                                <p v-if="!$v.item.supplier.required">
                                    {{ $t('validation.x_is_required', {x: $t('items.supplier')}) }}
                                </p>
                            </template>
                        </FormInputSelect>

                        <button v-if="$store.getters.hasRole('operations administrator')" type="button" class="button" @click="toggleCreateSupplier">
                            <font-awesome-icon :icon="['fal', 'plus']"/>
                        </button>
                    </div>


                    <FormInputSelect v-model="$v.item.purchaser.$model" identifier="purchaser"
                                     :label="$t('items.purchased_by')" :options="purchaser_options"
                                     :disabled="is_loading_purchasers || is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item"
                                     :has-error="$v.item.purchaser.$error" track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.item.purchaser.required">
                                {{ $t('validation.x_is_required', {x: $t('items.purchaser')}) }}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.item.category.$model" identifier="category"
                                     :label="$t('items.category')" :options="category_options"
                                     :disabled="is_loading_categories || is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item"
                                     :has-error="$v.item.category.$error" track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.item.category.required">
                                {{ $t('validation.x_is_required', {x: $t('items.category')}) }}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputText v-if="$store.getters.hasRole('finance administrator')" v-model="$v.item.category_number.$model"
                                   identifier="category" :label="$t('items.category_no')"
                                   :disabled="is_saving || is_loading_original_item" :has-error="$v.item.category_number.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.item.category_number.required">
                                {{$t('validation.x_is_required',{x: $t('items.category_no')})}}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputSelect v-if="$store.getters.hasRole('finance administrator')" v-model="$v.item.location.$model"
                                     identifier="location" :label="$t('items.location')" :options="location_options"
                                     :disabled="is_saving || is_loading_locations || is_loading_original_item" :has-error="$v.item.location.$error"
                                     track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.item.location.required">
                                {{ $t('validation.x_is_required', {x: $t('items.location')}) }}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-if="$store.getters.hasRole('finance administrator')" v-model="$v.item.assigned_to.$model"
                                     identifier="assigned_to" :label="$t('items.assigned_to')" :options="employee_options"
                                     :disabled="is_saving || is_loading_original_item" :has-error="$v.item.assigned_to.$error"
                                     track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.item.assigned_to.required">
                                {{ $t('validation.x_is_required', {x: $t('items.assigned_to')}) }}
                            </p>
                        </template>
                    </FormInputSelect>


                    <div v-if="$store.getters.hasRole('finance administrator')" class="multiselect-button-wrapper">
                        <FormInputSelect v-if="$store.getters.hasRole('finance administrator')" v-model="$v.item.type.$model"
                                         identifier="type" :label="$t('items.type')" :options="type_options"
                                         :disabled="is_saving || is_loading_types || is_loading_original_item" :has-error="$v.item.type.$error"
                                         track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                            <template v-slot:errors>
                                <p v-if="!$v.item.type.required">
                                    {{ $t('validation.x_is_required', {x: $t('items.type')}) }}
                                </p>
                            </template>
                        </FormInputSelect>

                        <button v-if="$store.getters.hasRole('finance administrator')" type="button" class="button" @click="toggleCreateType">
                            <font-awesome-icon :icon="['fal', 'plus']"/>
                        </button>
                    </div>

                    <FormInputText v-model="$v.item.serial_number.$model" identifier="serial_number" :label="$t('items.serial_no')"
                                   :disabled="is_saving || is_loading_original_item" :has-error="$v.item.serial_number.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.item.serial_number.required">
                                {{$t('validation.x_is_required',{x: $t('items.serial_no')})}}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-if="$store.getters.hasRole('finance administrator')" v-model="$v.item.sequence_number.$model"
                                   identifier="sequence_number" :label="$t('items.sequence_no')"
                                   :disabled="is_saving || is_loading_original_item" :has-error="$v.item.sequence_number.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.item.sequence_number.required">
                                {{$t('validation.x_is_required',{x: $t('items.sequence_no')})}}
                            </p>
                        </template>
                    </FormInputText>

                    <!--                    <FormInputSelect v-model="$v.item.depreciated_year.$model" identifier="depreciated_year"-->
                    <!--                                     :label="$t('items.depreciated_year')" :options="year_options"-->
                    <!--                                     :disabled="is_saving || !$store.getters.hasRole('operations administrator')" :has-error="$v.item.depreciated_year.$error">-->
                    <!--                        <template v-slot:errors>-->
                    <!--                            <p v-if="!$v.item.depreciated_year.required">-->
                    <!--                                {{ $t('validation.x_is_required', {x: $t('items.depreciated_year')}) }}-->
                    <!--                            </p>-->
                    <!--                        </template>-->
                    <!--                    </FormInputSelect>-->
                </FormGroupFour>

                <FormInputText v-model="$v.item.notes.$model" identifier="notes" :label="$t('items.notes')" :disabled="is_saving"/>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputDateTime from "../form/FormInputDateTime";
import FormInputSelect from "../form/FormInputSelect";
import {required, requiredIf} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import FormGroupFour from "@/components/form/FormGroupFour";
import _ from "lodash";
import SuppliersCreateModal from "@/components/suppliers/SuppliersCreateModal.vue";
import TypesCreateModal from "@/components/types/TypesCreateModal.vue";

export default {
    name: "ItemsCreateModal",
    components: {
        FormInputDateTime,
        FormGroupFour,
        FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            item: {
                description: null,
                found: null,
                unit_price: null,
                financial_year: null,
                order_number: null,
                invoice_date: null,
                invoice_number: null,
                supplier: null,
                purchaser: null,
                category: null,
                category_number: null,
                location: null,
                assigned_to: null,
                type: null,
                serial_number: null,
                sequence_number: null,
                notes: null
            },
            original_item: null,
            year_options: [],
            supplier_options: [],
            purchaser_options: [],
            category_options: [],
            location_options: [],
            employee_options: [],
            type_options: [],
            found_options: ['Yes', 'No'],
            is_loading_years: false,
            is_loading_suppliers: false,
            is_loading_purchasers: false,
            is_loading_categories: false,
            is_loading_locations: false,
            is_loading_employees: false,
            is_loading_types: false,
            is_loading_original_item: false,
            is_saving: false,
        }
    },
    props: {
        item_id: {
            type: Number,
            required: false
        }
    },
    validations: {
        item: {
            description: {required},
            found: {
                required: requiredIf(function() {
                    return this.$store.getters.hasRole('finance administrator');
                })
            },
            unit_price: {required},
            financial_year: {required},
            order_number: {required},
            invoice_date: {required},
            invoice_number: {required},
            supplier: {required},
            purchaser: {required},
            category: {required},
            category_number: {
                required: requiredIf(function() {
                    return this.$store.getters.hasRole('finance administrator');
                })
            },
            location: {
                required: requiredIf(function() {
                    return this.$store.getters.hasRole('finance administrator');
                })
            },
            assigned_to: {
                required: requiredIf(function() {
                    return this.$store.getters.hasRole('finance administrator');
                })
            },
            type: {
                required: requiredIf(function() {
                    return this.$store.getters.hasRole('finance administrator');
                })
            },
            serial_number: {
                required: requiredIf(function() {
                    return this.$store.getters.hasRole('finance administrator');
                })
            },
            sequence_number: {
                required: requiredIf(function() {
                    return this.$store.getters.hasRole('finance administrator');
                })
            },
            notes: {}
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('items-create-modal', status);
        },
        toggleCreateSupplier() {
            this.$modal.show(
                SuppliersCreateModal, {},
                {
                    name: 'suppliers-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getSupplierOptions();
                    }
                }
            );
        },
        toggleCreateType() {
            this.$modal.show(
                TypesCreateModal, {},
                {
                    name: 'types-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getTypeOptions();
                    }
                }
            );
        },
        async getYearOptions() {
            this.is_loading_years = true;

            let years = [];
            let date = new Date();

            for(let i = 0; i < 10; i++) {
                years.push(this.$moment(date).add('year', i+1).format('YYYY'));
            }

            for(let i = 0; i < 22; i++) {
                years.push(this.$moment(date).subtract('year', i).format('YYYY'));
            }

            years.sort((a, b) => parseInt(b) - parseInt(a));

            this.year_options = years;

            this.is_loading_years = false;
        },
        async getSupplierOptions() {
            this.is_loading_suppliers = true;

            await this.$axios.get('suppliers/list')
                .then(({data}) => {
                    this.supplier_options = data.data;
                    this.is_loading_suppliers = false;
                })
                .catch(e => {
                    this.is_loading_suppliers = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_suppliers')),
                        type: 'error',
                    });
                });
        },
        async getPurchaserOptions() {
            this.is_loading_purchasers = true;

            await this.$axios.get('purchasers/list')
                .then(({data}) => {
                    this.purchaser_options = data.data;
                    this.is_loading_purchasers = false;
                })
                .catch(e => {
                    this.is_loading_purchasers = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_purchasers')),
                        type: 'error',
                    });
                });
        },
        async getCategoryOptions() {
            this.is_loading_categories = true;

            await this.$axios.get('categories/list')
                .then(({data}) => {
                    this.category_options = data.data;
                    this.is_loading_categories = false;
                })
                .catch(e => {
                    this.is_loading_categories = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_categories')),
                        type: 'error',
                    });
                });
        },
        async getLocationOptions() {
            this.is_loading_locations = true;

            await this.$axios.get('locations/list')
                .then(({data}) => {
                    this.location_options = data.data;
                    this.is_loading_locations = false;
                })
                .catch(e => {
                    this.is_loading_locations = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_locations')),
                        type: 'error',
                    });
                });
        },
        async getEmployeeOptions() {
            this.is_loading_employees = true;

            await this.$axios.get('employees/list')
                .then(({data}) => {
                    this.employee_options = data.data;
                    this.is_loading_employees = false;
                })
                .catch(e => {
                    this.is_loading_employees = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_employees')),
                        type: 'error',
                    });
                });
        },
        async getTypeOptions() {
            this.is_loading_types = true;

            await this.$axios.get(`categories/${this.original_item.relationships.category.data.id}/types/list`)
                .then(({data}) => {
                    this.type_options = data.data;
                    this.is_loading_types = false;
                })
                .catch(e => {
                    this.is_loading_types = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_types')),
                        type: 'error',
                    });
                });
        },
        async getOriginalItem() {
            this.is_loading_original_item = true;

            await this.$axios.get(`items/${this.item_id}`)
                .then(async ({data}) => {
                    this.original_item = data.data;

                    this.is_loading_original_item = false;
                })
                .catch(e => {
                    this.is_loading_original_item = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_item')),
                        type: 'error',
                    });
                });
        },
        async populate() {
            this.is_loading_original_item = true;

            if (!this.original_item || !this.item)
                return;

            this.$v.item.description.$model    = this.original_item.attributes.description;
            this.$v.item.unit_price.$model     = this.original_item.attributes.unit_price;
            this.$v.item.financial_year.$model = this.original_item.attributes.financial_year;
            this.$v.item.order_number.$model   = this.original_item.attributes.order_number;
            this.$v.item.invoice_date.$model   = this.original_item.attributes.invoice_date;
            this.$v.item.invoice_number.$model = this.original_item.attributes.invoice_number;
            this.$v.item.supplier.$model       = _.find(this.supplier_options, {id: this.original_item.relationships.supplier.data.id});
            this.$v.item.purchaser.$model      = _.find(this.purchaser_options, {id: this.original_item.relationships.purchaser.data.id});
            this.$v.item.category.$model       = _.find(this.category_options, {id: this.original_item.relationships.category.data.id});

            if (this.original_item.attributes.serial_number)
                this.$v.item.serial_number.$model = this.original_item.attributes.serial_number;

            this.$v.item.notes.$model = this.original_item.attributes.note;

            this.is_loading_original_item = false;
        },
        save() {
            this.$v.item.$touch();
            if (this.$v.item.$anyError || this.is_saving)
                return;

            if (this.item_id) {
                this.update();
                return;
            }

            this.is_saving = true;

            let payload = {
                description: this.item.description,
                unit_price: this.item.unit_price,
                financial_year: this.$moment(this.item.financial_year).format('YYYY'),
                order_number: this.item.order_number,
                invoice_date: this.item.invoice_date,
                invoice_number: this.item.invoice_number,
                supplier_id: this.item.supplier.id,
                purchaser_id: this.item.purchaser.id,
                category_id: this.item.category.id,
                serial_number: this.item.serial_number,
                note: this.item.notes,
                status: 'pending'
            }

            this.$axios.post(`items`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('items.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('items.error_create')),
                    type: 'error',
                });
            });
        },
        update() {
            this.is_saving = true;

            let payload = {};

            if (this.$store.getters.hasRole('operations administrator')) {
                payload = {
                    description: this.item.description,
                    unit_price: this.item.unit_price,
                    financial_year: this.$moment(this.item.financial_year).format('YYYY'),
                    order_number: this.item.order_number,
                    invoice_date: this.item.invoice_date,
                    invoice_number: this.item.invoice_number,
                    supplier_id: this.item.supplier.id,
                    purchaser_id: this.item.purchaser.id,
                    category_id: this.item.category.id,
                    serial_number: this.item.serial_number,
                    note: this.item.notes,
                    status: 'pending'
                };
            } else if (this.$store.getters.hasRole('finance administrator')) {
                payload = {
                    description: this.item.description,
                    is_found: this.item.found === 'Yes' ? 1 : 0,
                    unit_price: this.item.unit_price,
                    financial_year: this.$moment(this.item.financial_year).format('YYYY'),
                    order_number: this.item.order_number,
                    invoice_date: this.item.invoice_date,
                    invoice_number: this.item.invoice_number,
                    supplier_id: this.item.supplier.id,
                    purchaser_id: this.item.purchaser.id,
                    category_id: this.item.category.id,
                    category_number: this.item.category_number,
                    location_id: this.item.location.id,
                    employee_id: this.item.assigned_to.id,
                    type_id: this.item.type.id,
                    serial_number: this.item.serial_number,
                    sequence_no: this.item.sequence_number,
                    note: this.item.notes,
                    full_code: this.item.full_code,
                    status: 'active'
                };
            }

            this.$axios.patch(`items/${this.item_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('items.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('items.error_update')),
                    type: 'error',
                });
            });
        }
    },
    async mounted() {
        this.getYearOptions();
        this.getSupplierOptions();
        this.getPurchaserOptions();
        this.getCategoryOptions();

        if (this.item_id) {
            await this.getOriginalItem();
            await this.getTypeOptions();
            await this.populate();
        }

        if (this.$store.getters.hasRole('finance administrator')) {
            this.getLocationOptions();
            this.getEmployeeOptions();
        }
    }
}
</script>