<template>
    <div class="input-group-four">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "FormGroupFour"
    }
</script>

<style lang="scss" scoped>
    .input-group-four {
        @apply flex flex-row items-start flex-wrap w-auto;

        @screen md {
            @apply -mx-2;
        }

        .input-group {
            @apply w-full px-0 mb-6;

            @screen md {
                @apply w-1/2 px-2;
            }

            @screen xl {
                @apply w-1/4;
            }
        }

        .multiselect-button-wrapper {
            @apply w-full px-0 mb-6 flex flex-row;

            @screen md {
                @apply w-1/2 px-2;
            }

            @screen xl {
                @apply w-1/4;
            }

            & > .input-group {
                @apply px-0 mb-0 w-full;
            }

            & > .button {
                @apply bg-secondary py-1 px-2 ml-4 mt-auto mb-2;

                &:hover {
                    @apply bg-secondary-darker;
                }

                & > svg {
                    @apply text-white text-xs;
                }
            }

        }
    }
</style>