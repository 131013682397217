<template>
    <div class="employees-create-modal">
        <ModalContainer :title="$t('items.write_off')" identifier="items-write-off-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputDateTime v-model="$v.details.written_off_at.$model" identifier="date" :label="$t('items.wo')"
                                   :disabled="is_saving" position="bottom" formatted="DD/MM/YYYY HH:mm" :has-error="$v.details.written_off_at.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.details.written_off_at.required">
                            {{ $t('validation.x_is_required', {x: $t('items.wo')}) }}
                        </p>
                    </template>
                </FormInputDateTime>

                <FormInputText v-model="$v.details.written_off_authority.$model" identifier="unit_price" :label="$t('items.wo_authority')"
                               :disabled="is_saving" :has-error="$v.details.written_off_authority.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.details.written_off_authority.required">
                            {{$t('validation.x_is_required',{x: $t('items.wo_authority')})}}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";
    import FormInputDateTime from "@/components/form/FormInputDateTime";

    export default {
        name: "ItemsWriteOffModal",
        components: {
            FormInputDateTime,
            FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        data() {
            return {
                details: {
                    written_off_at: null,
                    written_off_authority: null,
                },
                is_saving: false,
            }
        },
        validations: {
            details: {
                written_off_at: {required},
                written_off_authority: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('items-write-off-modal', status);
            },
            save() {
                this.$v.details.$touch();
                if (this.$v.details.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = {
                    written_off_at: this.details.written_off_at,
                    written_off_authority: this.details.written_off_authority,
                    status: 'written_off'
                };

                this.$axios.patch(`items/${this.$route.params.id}`, payload).then(({data}) => {
                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('items.error_write_off')),
                        type: 'error',
                    });
                });
            },
        },
    }
</script>
