<template>
    <div class="section">
        <Headbar>
            <template v-slot:left>
                <h1>{{ $t('categories.categories') }}</h1>
            </template>
            <template v-slot:right>
                <Button className="--primary --small" :onclick="toggleCreate">{{$t('categories.add_category')}}</Button>
            </template>
        </Headbar>

        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="categories"
                :isLoading.sync="is_loading_categories"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 25,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('categories.categories')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                  initialSortBy: {field: 'attributes.name', type: 'asc'}
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'after'" class="td-after">
                        <Button className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import CreateModal from "./CategoriesCreateModal";
import UpdateModal from "./CategoriesUpdateModal";

export default {
    name: "categories-index",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('categories.code'),
                field: 'attributes.code',
                sortable: true,
            },
            {
                label: this.$t('categories.name'),
                field: 'attributes.name',
                sortable: true,
            },
            {
                label: this.$t('categories.depreciation_value'),
                field: 'attributes.depreciation_value',
                sortable: true,
            },
        ];

        if (this.$store.getters.hasPermission('update categories'))
            columns.push({
                label: this.$t('categories.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            categories: [],
            is_loading_categories: false,
            totalRecords: null,
            serverParams: {
                sorting: [],
                per_page: 10
            },
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        toggleCreate() {
            this.$modal.show(
                CreateModal, {},
                {
                    name: 'categories-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveCategories();
                    }
                }
            );
        },
        toggleUpdate(category) {
            this.$modal.show(
                UpdateModal,
                {
                    category_id: category.id,
                },
                {
                    name: 'categories-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveCategories();
                    }
                }
            );
        },
        toggleDelete(category) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('categories.delete_category'),
                    message: this.$t('categories.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                },
                {
                'before-close': (e) => {
                    if (e.params === true) {
                        this.is_deleting = true;
                        this.$axios.delete(`categories/${category.id}`)
                            .then(({data}) => {
                                this.is_deleting = false;
                                this.retrieveCategories();
                            })
                            .catch(e => {
                                this.is_deleting = false;

                                this.$notify({
                                    title: this.$t('error'),
                                    text: this.$larerror(e.response.data, this.$t('categories.error_delete')),
                                    type: 'error',
                                });
                            });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveCategories();
        },
        onSortChange(params) {
            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveCategories();
            }

            let sort_by = null;
            let sort_order = params[0].type;

            params.forEach(p => {
                if (p.field === 'attributes.code')
                    sort_by = 'code';
                else if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.depreciation_value')
                    sort_by = 'depreciation_value';
                else {
                    sort_by = null;
                }
            });

            if(sort_order === 'none') {
                this.removeParam('sort_by');
                this.removeParam('sort_order');
            } else {
                this.updateParams({sort_by: sort_by, sort_order: sort_order});
            }

            this.retrieveCategories();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrieveCategories();
        },
        retrieveCategories() {
            this.is_loading_categories = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));


            this.$axios.get('categories', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.categories = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_categories = false;
                })
                .catch(e => {
                    this.is_loading_categories = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.retrieveCategories();
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    @apply flex flex-col;

    @screen lg {
        @apply flex-row;
    }

    main, .headbar-container {
        &:first-of-type {
            @screen lg {
                @apply pr-4;
            }

            @screen xl {
                @apply pr-6;
            }
        }

        &:last-of-type {
            @screen lg {
                @apply pl-4;
            }

            @screen xl {
                @apply pl-6;
            }
        }
    }

    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
