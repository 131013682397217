<template>
    <div class="purchasers-create-modal">
        <ModalContainer :title="$t('purchasers.add_purchaser')" identifier="purchasers-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.purchaser.name.$model" identifier="name" :label="$t('purchasers.name')"
                               :disabled="is_saving" :has-error="$v.purchaser.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.purchaser.name.required">
                            {{$t('validation.x_is_required',{x: $t('purchasers.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.purchaser.phone.$model" identifier="phone" :label="$t('purchasers.phone')"
                               :disabled="is_saving" :has-error="$v.purchaser.phone.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.purchaser.phone.required">
                            {{$t('validation.x_is_required',{x: $t('purchasers.phone')})}}
                        </p>
                    </template>
                </FormInputText>
                    
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "PurchasersCreateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        data() {
            return {
                purchaser: {
                    name: null,
                    phone: null,
                },
                is_saving: false,
            }
        },
        validations: {
            purchaser: {
                name: {required},
                phone: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('purchasers-create-modal', status);
            },
            save() {
                this.$v.purchaser.$touch();
                if (this.$v.purchaser.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.purchaser.$model);

                this.$axios.post(`purchasers`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('purchasers.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('purchasers.error_create')),
                        type: 'error',
                    });
                });
            },
        },
    }
</script>
