<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small" :onclick="toggleCreate">
                    {{ $t('purchasers.add_purchaser') }}
                </Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('purchasers.search_purchasers')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('purchasers.search_purchasers')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="purchasers"
                :isLoading.sync="is_loading_purchasers"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 25,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('purchasers.purchasers')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                  initialSortBy: {field: 'attributes.name', type: 'asc'}
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'after'" class="td-after">
                            <Button className="--secondary --outline --mini --big-text"
                                    :onclick="()=>toggleUpdate(props.row)">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                        <Button className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import PurchasersCreateModal from "../../components/purchasers/PurchasersCreateModal";
import PurchasersUpdateModal from "../../components/purchasers/PurchasersUpdateModal";

export default {
    name: "purchasers-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('purchasers.name'),
                field: 'attributes.name',
                sortable: true,
            },
            {
                label: this.$t('purchasers.phone'),
                field: 'attributes.phone',
                sortable: true,
            },
        ];

        if (this.$store.getters.hasPermission('update purchasers'))
            columns.push({
                label: this.$t('purchasers.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            purchasers: [],
            is_loading_purchasers: false,
            totalRecords: null,
            serverParams: {sorting: []},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        toggleCreate() {
            this.$modal.show(
                PurchasersCreateModal, {},
                {
                    name: 'purchasers-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrievePurchasers();
                    }
                }
            );
        },
        toggleUpdate(purchaser) {
            this.$modal.show(
                PurchasersUpdateModal,
                {
                    purchaser_id: purchaser.id,
                },
                {
                    name: 'purchasers-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrievePurchasers();
                    }
                }
            );
        },
        toggleDelete(purchaser) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('purchasers.delete_purchaser'),
                    message: this.$t('purchasers.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`purchasers/${purchaser.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrievePurchasers();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('purchasers.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrievePurchasers();
        },
        onSortChange(params) {
            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrievePurchasers();
            }

          let sort_by = null;
          let sort_order = params[0].type;

          params.forEach(p => {
            if (p.field === 'attributes.phone')
              sort_by = 'phone';
            else if (p.field === 'attributes.name')
              sort_by = 'name';
            else {
              sort_by = null;
            }
          });

          if (sort_order === 'none') {
            this.removeParam('sort_by');
            this.removeParam('sort_order');
          } else {
            this.updateParams({sort_by: sort_by, sort_order: sort_order});
          }
            this.retrievePurchasers();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrievePurchasers();
        },
        retrievePurchasers() {
            this.is_loading_purchasers = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('purchasers', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.purchasers = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_purchasers = false;
                })
                .catch(e => {
                    this.is_loading_purchasers = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('purchasers.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.retrievePurchasers();
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.purchasers')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
