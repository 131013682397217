<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button v-if="$store.getters.hasRole('operations administrator')" className="--primary --small"
                        :onclick="toggleCreate">
                    <!--                <Button className="&#45;&#45;primary &#45;&#45;small" :onclick="toggleCreate">-->
                    {{ $t('items.add_item') }}
                </Button>
            </template>
            <template v-slot:right>
                <div class="filter-desktop">
                    <div class="filter-wrapper">
                        <p>{{ $t('items.filter_by') }}</p>

                        <div class="filter-container">
                            <FormInputSelect v-model="filter_found" className="filter" :placeholder="$t('items.found')"
                                             :options="found_options" identifier="found" @input="onFilterUpdated"
                                             :display-custom-label="(row) => `${row.name}`"/>
                        </div>
                        <div class="filter-container">
                            <FormInputSelect v-model="filter_category" className="filter" :placeholder="$t('items.category')"
                                             :options="category_options" :disabled="is_loading_categories" identifier="category" @input="onFilterUpdated"
                                             :display-custom-label="(row) => `${row.attributes.code}`"/>
                        </div>
                        <div class="filter-container">
                            <FormInputSelect v-model="filter_depreciated" className="filter" :placeholder="$t('items.depreciated')"
                                             :options="depreciated_options" identifier="depreciate" @input="onFilterUpdated"
                                             :display-custom-label="(row) => `${row.name}`"/>
                        </div>
                        <div class="filter-container">
                            <FormInputSelect v-model="filter_financial_year" className="filter" :placeholder="$t('items.financial_year')"
                                             :options="financial_year_options" identifier="financial_year" @input="onFilterUpdated"
                                             :display-custom-label="(row) => `${row.name}`"/>
                        </div>
                        <div class="filter-container">
                            <FormInputSelect v-model="filter_status" className="filter" :placeholder="$t('items.status')"
                                             :options="status_options" :disabled="is_loading_statuses" identifier="status" @input="onFilterUpdated"
                                             :display-custom-label="(row) => `${row.name}`"/>
                        </div>
                    </div>
                </div>

                <Search class="search-desktop" :placeholder="$t('items.search_items')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <div class="filter-container" v-if="headbarExpanded === 'search'">
                        <FormInputSelect v-model="filter_found" className="filter" :placeholder="$t('items.found')"
                                         :options="found_options" identifier="found" @input="onFilterUpdated"
                                         :display-custom-label="(row) => `${row.name}`"/>
                    </div>

                    <div class="filter-container" v-if="headbarExpanded === 'search'">
                        <FormInputSelect v-model="filter_category" className="filter" :placeholder="$t('items.category')"
                                         :options="category_options" identifier="category" @input="onFilterUpdated"
                                         :display-custom-label="(row) => `${row.attributes.code}`"/>
                    </div>

                    <div class="filter-container" v-if="headbarExpanded === 'search'">
                        <FormInputSelect v-model="filter_depreciated" className="filter" :placeholder="$t('items.depreciated')"
                                         :options="depreciated_options" identifier="depreciate" @input="onFilterUpdated"
                                         :display-custom-label="(row) => `${row.name}`"/>
                    </div>

                    <div class="filter-container" v-if="headbarExpanded === 'search'">
                        <FormInputSelect v-model="filter_financial_year" className="filter" :placeholder="$t('items.financial_year')"
                                         :options="financial_year_options" identifier="financial_year" @input="onFilterUpdated"
                                         :display-custom-label="(row) => `${row.name}`"/>
                    </div>

                    <div class="filter-container" v-if="headbarExpanded === 'search'">
                        <FormInputSelect v-model="filter_status" className="filter" :placeholder="$t('items.status')"
                                         :options="status_options" identifier="status" @input="onFilterUpdated"
                                         :display-custom-label="(row) => `${row.name}`"/>
                    </div>

                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('items.search_items')"
                            @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="items"
                :isLoading.sync="is_loading_items"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 25,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('items.items')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'status'" class="td-status">
                        <p :class="{'active': props.row.attributes.status === 'active',
                           'completed': props.row.attributes.status === 'completed',
                           'disposed-of': props.row.attributes.status === 'disposed_of',
                           'written-off': props.row.attributes.status === 'written_off',
                           'status': props.row.attributes.status !== 'pending'}">
                            {{status(props.row.attributes.status)}}</p>
                    </div>

                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <router-link v-if="props.row.attributes.status !== 'pending'"
                                     :to="{name: 'items-update', params: {id: props.row.id}}">
                            <Button className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                        </router-link>
                        <Button v-else-if="props.row.attributes.status === 'pending'"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import ItemsCreateModal from "../../components/items/ItemsCreateModal";
import FormInputSelect from "@/components/form/FormInputSelect.vue";

export default {
    name: "items-index-page",
    components: {FormInputSelect, ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('items.full_code'),
                field: 'attributes.full_code',
                sortable: true,
            },
            {
                label: this.$t('items.found'),
                field: row => row.attributes.is_found ? 'Yes' : 'No',
                sortable: false,
            },
            {
                label: this.$t('items.category'),
                field: 'relationships.category.data.attributes.code',
                sortable: true,
            },
            {
                label: this.$t('items.location'),
                field: 'relationships.location.data.attributes.name',
                sortable: true,
            },
            {
                label: this.$t('items.description'),
                field: 'attributes.description',
                sortable: false,
            },
            {
                label: this.$t('items.type'),
                field: 'relationships.type.data.attributes.name',
                sortable: true,
            },
            {
                label: this.$t('items.financial_year'),
                field: 'attributes.financial_year',
                sortable: true,
            },
            {
                label: this.$t('items.order_no'),
                field: 'attributes.order_number',
                sortable: true,
            },
            {
                label: this.$t('items.invoice_date'),
                field: row => this.$moment(row.attributes.invoice_date).format('DD/MM/YYYY'),
                sortable: true,
            },
            {
                label: this.$t('items.invoice_no'),
                field: 'attributes.invoice_number',
                sortable: true,
            },
            {
                label: this.$t('items.status'),
                field: 'status',
                sortable: false
            }
        ];

        if (this.$store.getters.hasPermission('update items'))
            columns.push({
                label: this.$t('items.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            items: [],
            itemsOptions: [],
            found_options: [{name: 'No', value: 0}, {name: 'Yes', value: 1}],
            category_options: [],
            status_options: [],
            depreciated_options: [ {name: 'Fully', value: 1}, {name: 'Partially', value: 0}],
            financial_year_options: [
                {name: '2000', value: '2000'},
                {name: '2001', value: '2001'},
                {name: '2002', value: '2002'},
                {name: '2003', value: '2003'},
                {name: '2004', value: '2004'},
                {name: '2005', value: '2005'},
                {name: '2006', value: '2006'},
                {name: '2007', value: '2007'},
                {name: '2008', value: '2008'},
                {name: '2009', value: '2009'},
                {name: '2010', value: '2010'},
                {name: '2011', value: '2011'},
                {name: '2012', value: '2012'},
                {name: '2013', value: '2013'},
                {name: '2014', value: '2014'},
                {name: '2015', value: '2015'},
                {name: '2016', value: '2016'},
                {name: '2017', value: '2017'},
                {name: '2018', value: '2018'},
                {name: '2019', value: '2019'},
                {name: '2020', value: '2020'},
                {name: '2021', value: '2021'},
                {name: '2022', value: '2022'},
                {name: '2023', value: '2023'},
                {name: '2024', value: '2024'},
                {name: '2025', value: '2025'},
                {name: '2026', value: '2026'},
                {name: '2027', value: '2027'},
                {name: '2028', value: '2028'},
                {name: '2029', value: '2029'},
                {name: '2030', value: '2030'}
            ],
            totalRecords: null,
            serverParams: {},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false,
            filter_found: null,
            filter_depreciated: null,
            filter_category: null,
            filter_financial_year: null,
            filter_status: null,
            is_loading_items: false,
            is_loading_categories: false,
            is_loading_statuses: false,
        }
    },
    computed: {
        currentYear() {
            return this.$moment(new Date()).format('YYYY');
        }
    },
    methods: {
        status(status) {
            return status.slice(0).split('_').join(' ');
        },
        calculateDepreciation(item) {
            let final_depreciation_values = [];
            let total_depreciation_years = 100 / item.relationships.category.data.attributes.depreciation_value; //total years to depreciate

            for (let i = 1; i <= total_depreciation_years; i++) {
                final_depreciation_values.push({
                    year: this.$moment(item.attributes.financial_year).add('years', i - 1).format('YYYY'),
                    value: item.attributes.unit_price - (parseFloat((item.attributes.unit_price * ((item.relationships.category.data.attributes.depreciation_value / 100)).toFixed(2)) * i))
                })
            }

            return this.currentYear < final_depreciation_values[final_depreciation_values.length - 1].year ? 'Partially' : 'Fully';
        },
        toggleCreate() {
            this.$modal.show(
                ItemsCreateModal, {},
                {
                    name: 'items-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 1100,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getItems();
                    }
                }
            );
        },
        toggleUpdate(item) {
            this.$modal.show(
                ItemsCreateModal, {
                    item_id: item.id
                },
                {
                    name: 'items-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 1000,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getItems();
                    }
                }
            );
        },
        toggleDelete(item) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('items.delete_item'),
                    message: this.$t('items.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`items/${item.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.getItems();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('items.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getItems();
        },
        onSortChange(params) {
            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.getItems();
            }

            let sort_by = null;
            let sort_order = params[0].type;

            params.forEach(p => {
                if (p.field === 'attributes.full_code') {
                    sort_by = 'full_code';
                } else if (p.field === '') {
                    sort_by = 'found'
                } else if (p.field === 'relationships.category.data.attributes.code') {
                    sort_by = 'category_id';
                } else if (p.field === 'relationships.location.data.attributes.name') {
                    sort_by = 'location_id';
                } else if (p.field === 'attributes.type') {
                    sort_by = 'type';
                } else if (p.field === 'attributes.financial_year') {
                    sort_by = 'financial_year';
                } else if (p.field === 'attributes.order_number') {
                    sort_by = 'order_number';
                } else if (p.field === 'this.$moment(row.attributes.invoice_date)') {
                    sort_by = 'invoice_date';
                } else if (p.field === 'attributes.invoice_no') {
                    sort_by = 'invoice_no';
                }
            });

            if(sort_order === 'none') {
                this.removeParam('sort_by');
                this.removeParam('sort_order');
            } else {
                this.updateParams({sort_by: sort_by, sort_order: sort_order});
            }

            this.getItems();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.getItems();
        },
        getItems() {
            this.is_loading_items = true;

            this.$axios.get('items', {params: {...this.serverParams}})
                .then(({data}) => {
                    this.items = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_items = false;
                })
                .catch(e => {
                    this.is_loading_items = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        onFilterUpdated() {
            const filters = [];

            if (this.filter_found)
                filters.push({filter_by: 'is_found', filter_value: this.filter_found.value});

            if (this.filter_category)
                filters.push({filter_by: 'category_id', filter_value: this.filter_category.id});

            if (this.filter_depreciated)
                filters.push({filter_by: 'is_depreciated', filter_value: this.filter_depreciated.value});

            if (this.filter_financial_year)
                filters.push({filter_by: 'financial_year', filter_value: this.filter_financial_year.value});

            if (this.filter_status)
                filters.push({filter_by: 'status', filter_value: this.filter_status.column});

            if (filters.length)
                this.updateParams({
                    filters: filters
                });
            else this.removeParam('filters');

            this.getItems();
        },
        async getCategoryOptions() {
            this.is_loading_categories = true;

            await this.$axios.get('categories/list')
                .then(({data}) => {
                    const sortedData = data.data.sort((a, b) => a.attributes.code.localeCompare(b.attributes.code));
                    this.category_options = sortedData;
                    this.is_loading_categories = false;
                })
                .catch(e => {
                    this.is_loading_categories = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_categories')),
                        type: 'error',
                    });
                });
        },
        async getStatusOptions() {
            this.is_loading_statuses = true;

            await this.$axios.get('items/listStatuses')
                .then(({data}) => {
                    const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
                    this.status_options = sortedData;
                    this.is_loading_statuses = false;
                })
                .catch(e => {
                    this.is_loading_statuses = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_statuses')),
                        type: 'error',
                    });
                });
        },
    },
    mounted() {
        this.getCategoryOptions();
        this.getStatusOptions();
        this.getItems();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.items')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen lg {
            @apply hidden;
        }

        .filter-container {
            @apply min-w-50;
        }
    }

    .filter-wrapper {
        @apply flex flex-row gap-x-4;

        & > p {
            @apply my-auto text-xs text-secondary font-bold;
        }

        .filter-container {
            @apply flex flex-row gap-x-4 items-center w-full;

            @screen lg {
                @apply w-auto;
            }

            & > p {
                @apply text-primary font-bold text-xs;
            }

            .input-group {
                @apply w-36 mb-0 ml-auto;

                @screen lg {
                    @apply ml-0;
                }
            }

            &.date-container {
                & > .input-group {
                    @apply min-w-50;
                }
            }
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen lg {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen lg {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .td-status {
        @apply capitalize;

        .status {
            @apply w-min text-white px-3 py-1 rounded-lg text-sm;
            text-wrap: nowrap;

            &.active {
                @apply bg-positive;
            }

            &.completed {
                @apply bg-yellow;
            }

            &.written-off {
                @apply bg-neutral;
            }

            &.disposed-of {
                @apply bg-negative;
            }
        }
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
