<template>
    <aside class="sidebar-container">
        <router-link :to="{name: 'items-index'}" class="nav-item">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'box']"/>
            </div>
            <p>{{$t('nav.items')}}</p>
        </router-link>

        <router-link :to="{name: 'categories-index'}" class="nav-item" v-if="$store.getters.hasPermission('read categories')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'icons']"/>
            </div>
            <p>{{$t('nav.categories')}}</p>
        </router-link>

        <router-link :to="{name: 'locations-index'}" class="nav-item" v-if="$store.getters.hasPermission('read locations')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'map-marker']"/>
            </div>
            <p>{{$t('nav.locations')}}</p>
        </router-link>

        <router-link :to="{name: 'employees-index'}" class="nav-item" v-if="$store.getters.hasPermission('read employees')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'user-crown']"/>
            </div>
            <p>{{$t('nav.employees')}}</p>
        </router-link>

        <router-link :to="{name: 'suppliers-index'}" class="nav-item" v-if="$store.getters.hasPermission('read suppliers')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'person-carry']"/>
            </div>
            <p>{{$t('nav.suppliers')}}</p>
        </router-link>

        <router-link :to="{name: 'purchasers-index'}" class="nav-item" v-if="$store.getters.hasPermission('read purchasers')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'user-check']"/>
            </div>
            <p>{{$t('nav.purchasers')}}</p>
        </router-link>

        <router-link :to="{name: 'roles-index'}" class="nav-item" v-if="$store.getters.hasPermission('read roles')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{$t('nav.roles_permissions')}}</p>
        </router-link>

        <router-link :to="{name: 'users-index'}" class="nav-item" v-if="$store.getters.hasPermission('read users')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{$t('nav.users')}}</p>
        </router-link>
    </aside>
</template>

<script>
    export default {
        name: "Sidebar"
    }
</script>

<style lang="scss" scoped>
    .sidebar-container {
        @apply h-full flex flex-col bg-white overflow-y-auto hidden py-4 justify-between;

        box-shadow: 5px 0px 10px #0000001A;
        width: 130px;
        grid-area: sidebar;

        @screen md {
            @apply flex;
        }

        @screen 3xl {
            @apply py-8;
        }

        .nav-item {
            @apply flex flex-col items-center px-2;

            .icon-container {
                @apply p-2 rounded-lg border-2 border-grey-light flex flex-col items-center justify-center;
                height: 40px;
                width: 40px;

                svg {
                    @apply text-black w-auto;
                    height: 18px;
                }
            }

            p {
                @apply text-black text-xs font-bold mt-2 text-center;

                @screen 3xl {
                    @apply text-sm mt-4;
                }
            }

            &:hover, &:focus {
                .icon-container {
                    @apply border-secondary;
                }
            }

            &.router-link-active {
                .icon-container {
                    @apply bg-secondary border-secondary;

                    svg {
                        @apply text-white;
                    }
                }
            }
        }
    }
</style>