<template>
    <div class="locations-update-modal">
        <ModalContainer :title="$t('locations.edit_location')" identifier="locations-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_loading_original || is_saving">
                <FormInputText v-model="$v.location.name.$model" identifier="name" :label="$t('locations.name')"
                               :disabled="is_loading_original || is_saving" :has-error="$v.location.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.location.name.required">
                            {{$t('validation.x_is_required',{x: $t('locations.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "LocationsUpdateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        props: {
            location_id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                original: null,
                location: {
                    name: null,
                },
                is_saving: false,
                is_loading_original: false,
            }
        },
        validations: {
            location: {
                name: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('locations-update-modal', status);
            },
            save() {
                this.$v.location.$touch();
                if (this.$v.location.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                let payload = {}

                if (this.original.attributes.name !== this.$v.location.name.$model)
                    payload.name = this.$v.location.name.$model;

                this.$axios.patch(`locations/${this.location_id}`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('locations.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('locations.error_update')),
                        type: 'error',
                    });
                });
            },
            populate() {
                if (!this.original || !this.location)
                    return;

                this.$v.location.name.$model = this.original.attributes.name;
            },
            async retrieveOriginalLocation() {
                this.is_loading_original = true;

                await this.$axios.get(`locations/${this.location_id}`)
                    .then(({data}) => {
                        this.original = data.data;
                        this.is_loading_original = false;
                    })
                    .catch(e => {
                        this.is_loading_original = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('locations.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        async mounted() {
            await this.retrieveOriginalLocation();
            this.populate();
        }
    }
</script>
