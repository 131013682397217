<template>
    <loading-screen v-if="is_loading || is_loading_original_item || !original_item"/>
    <div v-else-if="!is_loading && !is_loading_original_item && original_item" class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1>{{original_item.attributes.full_code}}</h1>
            </template>
            <template v-slot:right>
                <div class="headbar-wrapper">
                    <div class="status-container">
                        <p class="status" v-if="original_item.attributes.status"
                           :class="{'active': original_item.attributes.status === 'active',
                           'completed': original_item.attributes.status === 'completed',
                           'disposed-of': original_item.attributes.status === 'disposed_of',
                           'written-off': original_item.attributes.status === 'written_off'}"
                           >{{status}}</p>

                        <p class="text" v-if="original_item.attributes.written_off_at">{{$t('items.written_off_at')}} {{$moment(original_item.attributes.written_off_at).format('DD/MM/YYYY - HH:mm')}}</p>
                        <p class="text" v-if="original_item.attributes.disposed_of_at">{{$t('items.disposed_of_at')}} {{$moment(original_item.attributes.disposed_of_at).format('DD/MM/YYYY - HH:mm')}}</p>
                    </div>

                    <div class="buttons-container">
                        <Button v-if="!original_item.attributes.written_off_at && !original_item.attributes.disposed_of_at && $store.getters.hasRole('operations administrator')"
                                className="--primary --small --outline" :onclick="toggleWriteOff">
                            {{$t('items.write_off')}}
                        </Button>

                        <Button v-if="original_item.attributes.written_off_at && !original_item.attributes.disposed_of_at && $store.getters.hasRole('finance administrator')"
                                className="--primary --small --outline" :class="{spinner: is_disposing}" :onclick="toggleDisposeOf">
                            {{$t('items.dispose_of')}}
                        </Button>

                        <Button className="--primary --small --wide" :disabled="is_disposing" :class="{spinner: is_saving}" :onclick="update">
                            {{$t('save')}}
                        </Button>
                    </div>
                </div>
            </template>
        </Headbar>
        <main>
            <div class="main-container">
                <Form class="form details-container" :disabled="is_saving">
                    <SectionHeader :title="$t('items.details')"/>

                    <div class="form-body">
                        <FormGroupThree>
                            <FormInputText v-model="$v.item.description.$model" identifier="description" :label="$t('items.description')"
                                           :disabled="is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item"
                                           :has-error="$v.item.description.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.description.required">
                                        {{$t('validation.x_is_required',{x: $t('items.description')})}}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputSelect v-model="$v.item.financial_year.$model" identifier="financial_year"
                                             :label="$t('items.financial_year')" :options="year_options"
                                             :disabled="is_loading_years || is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item" :has-error="$v.item.financial_year.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.financial_year.required">
                                        {{ $t('validation.x_is_required', {x: $t('items.financial_year')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputSelect v-model="$v.item.found.$model"
                                             identifier="found" :label="$t('items.found')" :options="found_options"
                                             :disabled="is_saving || !$store.getters.hasRole('finance administrator') || is_loading_original_item" :has-error="$v.item.found.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.found.required">
                                        {{ $t('validation.x_is_required', {x: $t('items.found')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputText v-model="$v.item.unit_price.$model" identifier="unit_price" :label="$t('items.unit_price')"
                                           :disabled="is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item" :has-error="$v.item.unit_price.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.unit_price.required">
                                        {{$t('validation.x_is_required',{x: $t('items.unit_price')})}}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputText v-model="$v.item.order_number.$model" identifier="order_number" :label="$t('items.order_no')"
                                           :disabled="is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item" :has-error="$v.item.order_number.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.order_number.required">
                                        {{$t('validation.x_is_required',{x: $t('items.order_no')})}}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputDateTime v-model="$v.item.invoice_date.$model" identifier="date" :label="$t('items.invoice_date')"
                                               :disabled="is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item"
                                               position="bottom" :onlyDate="true" formatted="DD/MM/YYYY" :has-error="$v.item.invoice_date.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.invoice_date.required">
                                        {{ $t('validation.x_is_required', {x: $t('items.invoice_date')}) }}
                                    </p>
                                </template>
                            </FormInputDateTime>

                            <FormInputText v-model="$v.item.invoice_number.$model" identifier="invoice_number" :label="$t('items.invoice_no')"
                                           :disabled="is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item" :has-error="$v.item.invoice_number.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.invoice_number.required">
                                        {{$t('validation.x_is_required',{x: $t('items.invoice_no')})}}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputSelect v-model="$v.item.supplier.$model" identifier="supplier"
                                             :label="$t('items.supplier')" :options="supplier_options"
                                             :disabled="is_loading_suppliers || is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item"
                                             :has-error="$v.item.supplier.$error" track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.supplier.required">
                                        {{ $t('validation.x_is_required', {x: $t('items.supplier')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputSelect v-model="$v.item.purchaser.$model" identifier="purchaser"
                                             :label="$t('items.purchased_by')" :options="purchaser_options"
                                             :disabled="is_loading_purchasers || is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item"
                                             :has-error="$v.item.purchaser.$error" track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.purchaser.required">
                                        {{ $t('validation.x_is_required', {x: $t('items.purchaser')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputSelect v-model="$v.item.category.$model" identifier="category"
                                             :label="$t('items.category')" :options="category_options"
                                             :disabled="is_loading_categories || is_saving || !$store.getters.hasRole('operations administrator') || is_loading_original_item"
                                             :has-error="$v.item.category.$error" track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.category.required">
                                        {{ $t('validation.x_is_required', {x: $t('items.category')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputText v-model="$v.item.category_number.$model"
                                           identifier="category" :label="$t('items.category_no')"
                                           :disabled="is_saving || !$store.getters.hasRole('finance administrator') || is_loading_original_item" :has-error="$v.item.category_number.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.category_number.required">
                                        {{$t('validation.x_is_required',{x: $t('items.category_no')})}}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputText v-model="$v.item.sequence_number.$model" identifier="sequence_number" :label="$t('items.sequence_no')"
                                           :disabled="is_saving || is_loading_original_item || !$store.getters.hasRole('finance administrator')" :has-error="$v.item.sequence_number.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.sequence_number.required">
                                        {{$t('validation.x_is_required',{x: $t('items.sequence_no')})}}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputSelect v-model="$v.item.location.$model"
                                             identifier="location" :label="$t('items.location')" :options="location_options"
                                             :disabled="is_saving || is_loading_locations || !$store.getters.hasRole('finance administrator') || is_loading_original_item" :has-error="$v.item.location.$error"
                                             track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.location.required">
                                        {{ $t('validation.x_is_required', {x: $t('items.location')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputSelect v-model="$v.item.assigned_to.$model"
                                             identifier="assigned_to" :label="$t('items.assigned_to')" :options="employee_options"
                                             :disabled="is_saving || !$store.getters.hasRole('finance administrator') || is_loading_original_item" :has-error="$v.item.assigned_to.$error"
                                             track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.assigned_to.required">
                                        {{ $t('validation.x_is_required', {x: $t('items.assigned_to')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputSelect v-model="$v.item.type.$model"
                                             identifier="type" :label="$t('items.type')" :options="type_options"
                                             :disabled="is_saving || is_loading_types || !$store.getters.hasRole('finance administrator') || is_loading_original_item" :has-error="$v.item.type.$error"
                                             track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.type.required">
                                        {{ $t('validation.x_is_required', {x: $t('items.type')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputText v-model="$v.item.serial_number.$model" identifier="serial_number" :label="$t('items.serial_no')"
                                           :disabled="is_saving || is_loading_original_item" :has-error="$v.item.serial_number.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.item.serial_number.required">
                                        {{$t('validation.x_is_required',{x: $t('items.serial_no')})}}
                                    </p>
                                </template>
                            </FormInputText>
                        </FormGroupThree>

                        <FormInputText v-model="$v.item.notes.$model" identifier="notes" :label="$t('items.notes')" :disabled="is_saving"/>
                    </div>
                </Form>

                <div class="financial-breakdown-container">
                    <SectionHeader :title="$t('items.financial_breakdown')"/>

                    <div class="form-body">
                        <div class="details-container">
                            <div class="details-row border-bottom">
                                <div class="detail-item">
                                    <p class="label">{{$t('items.depreciated')}}</p>
                                    <p class="text">{{currentYear < final_depreciation_values[final_depreciation_values.length-1].year ? 'Partially' : 'Fully'}}</p>
                                </div>

                                <div class="detail-item">
                                    <p class="label">{{$t('items.depreciation_in_years')}}</p>
                                    <p class="text">{{total_depreciation_years}}</p>
                                </div>

                                <div class="detail-item">
                                    <p class="label">{{$t('items.depreciation_in_months')}}</p>
                                    <p class="text">{{total_depreciation_months}}</p>
                                </div>
                            </div>

                            <div class="details-row values-row">
                                <div class="detail-item" v-for="value in final_depreciation_values">
                                    <p class="label">{{value.year}}</p>
                                    <p class="text">€{{value.value.toFixed(2)}}</p>
                                </div>
                            </div>

                            <div class="details-row">
                                <div class="detail-item">
                                    <p class="label">{{$t('items.total_depreciation')}}</p>
                                    <p class="text">€{{current_total_depreciation > original_item.attributes.unit_price ? original_item.attributes.unit_price.toFixed(2) : current_total_depreciation.toFixed(2)}}</p>
                                </div>

                                <div class="detail-item">
                                    <p class="label">{{$t('items.balance_carried_forward')}}</p>
                                    <p class="text">{{currentYear < final_depreciation_values[final_depreciation_values.length-1].year ? 'Partially' : 'None'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="audits-container">
                <div class="title-container">
                    <h1>{{ $t('audits.audits') }}</h1>
                </div>
                <vue-good-table
                    mode="remote"
                    styleClass="vgt-table vgt-custom"
                    :columns="auditsColumns"
                    :rows="audits"
                    :isLoading.sync="is_loading_audits"
                    :search-options="{enabled: false}"
                    :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('audits.audits')}),
                }"
                    :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                    :totalRows="totalRecordsAudits"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'status'">
                            <div class="not-available-container">
                                <div class="not-available-circle"></div>
                                <p>{{$t('profile.not_available')}}</p>
                            </div>
                        </div>
                        <div v-else-if="props.column.field === 'attributes.event'">
                            <p style="text-transform: capitalize;">{{ props.row.attributes.event}}</p>
                        </div>
                        <div v-else-if="props.column.field === 'values'" class="td-values">
                            <div v-if="Object.keys(props.row.attributes.old_values).length" class="row">
                                <p class="name"><strong>{{$t('items.old_values')}}</strong></p>
                                <div class="values">
                                    <p style="text-transform: capitalize" v-for="key in Object.keys(props.row.attributes.old_values)">{{$t(`items.${key}`)}}: {{props.row.attributes.old_values[key]}}</p>
                                </div>
                            </div>
                            <div v-if="Object.keys(props.row.attributes.new_values).length" class="row">
                                <p class="name"><strong>{{$t('items.new_values')}}</strong></p>
                                <div class="values">
                                    <p style="text-transform: capitalize" v-for="key in Object.keys(props.row.attributes.new_values)">{{$t(`items.${key}`)}}: {{props.row.attributes.new_values[key]}}</p>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="props.column.field === 'attributes.created_at'">
                            <p style="text-transform: capitalize;">{{$moment(props.row.attributes.created_at).format('DD/MM/YYYY - HH:mm')}}</p>
                        </div>
                        <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import LoadingScreen from "@/components/LoadingScreen";
import ItemsWriteOffModal from "@/components/items/ItemsWriteOffModal";
import Form from "@/components/form/Form";
import SectionHeader from "@/components/SectionHeader";
import FormGroupThree from "@/components/form/FormGroupThree";
import {required, requiredIf} from "vuelidate/lib/validators";
import FormInputText from "@/components/form/FormInputText";
import FormInputSelect from "@/components/form/FormInputSelect";
import FormInputDateTime from "@/components/form/FormInputDateTime";
import _ from "lodash";

export default {
    name: "items-update-page",
    components: {
        FormInputDateTime,
        FormInputSelect,
        FormInputText,
        FormGroupThree, SectionHeader, Form, LoadingScreen, ConfirmModal, Select, Button, Headbar},
    data() {
        return {
            original_item: null,
            item: {
                description: null,
                found: null,
                unit_price: null,
                financial_year: null,
                order_number: null,
                invoice_date: null,
                invoice_number: null,
                supplier: null,
                purchaser: null,
                category: null,
                category_number: null,
                location: null,
                assigned_to: null,
                type: null,
                serial_number: null,
                sequence_number: null,
                notes: null
            },
            year_options: [],
            supplier_options: [],
            purchaser_options: [],
            category_options: [],
            location_options: [],
            employee_options: [],
            type_options: [],
            found_options: ['Yes', 'No'],
            final_depreciation_values: [],
            audits: [],
            totalRecordsAudits: null,
            serverParams: {sorting: []},
            auditsColumns: [
                {
                    label: this.$t('audits.user'),
                    field: 'relationships.user.data.attributes.name',
                    sortable: false,
                },
                {
                    label: this.$t('audits.event'),
                    field: 'attributes.event',
                    sortable: false,
                },
                {
                    label: this.$t('audits.detail'),
                    field: 'values',
                    sortable: false,
                },
                {
                    label: this.$t('audits.date_time'),
                    field: 'attributes.created_at',
                    sortable: false,
                },
            ],
            total_depreciation_years: 0,
            total_depreciation_months: 0,
            current_total_depreciation: 0,
            is_disposing: false,
            is_saving: false,
            is_loading: false,
            is_loading_years: false,
            is_loading_suppliers: false,
            is_loading_purchasers: false,
            is_loading_categories: false,
            is_loading_locations: false,
            is_loading_employees: false,
            is_loading_types: false,
            is_loading_audits: false,
            is_loading_original_item: false,
        }
    },
    validations: {
        item: {
            description: {required},
            found: {required},
            unit_price: {required},
            financial_year: {required},
            order_number: {required},
            invoice_date: {required},
            invoice_number: {required},
            supplier: {required},
            purchaser: {required},
            category: {required},
            category_number: {required},
            location: {required},
            assigned_to: {required},
            type: {required},
            serial_number: {required},
            sequence_number: {required},
            notes: {}
        }
    },
    computed: {
        currentYear() {
            return this.$moment(new Date()).format('YYYY');
        },
        status() {
            return this.original_item.attributes.status.slice(0).split('_').join(' ');
        }
    },
    methods: {
        async getOriginalItem() {
            this.is_loading = true;

            await this.$axios.get(`items/${this.$route.params.id}`)
                .then(({data}) => {
                    this.original_item = data.data;
                    this.calculateDepreciation();

                    this.is_loading = false;
                })
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_item')),
                        type: 'error',
                    });

                    this.is_loading = false;
                });
        },
      async getYearOptions() {
          this.is_loading_years = true;

          let years = [];
          let date = new Date();

          for(let i = 0; i < 10; i++) {
            years.push(this.$moment(date).add('year', i+1).format('YYYY'));
          }

          for(let i = 0; i < 22; i++) {
            years.push(this.$moment(date).subtract('year', i).format('YYYY'));
          }

          years.sort((a, b) => parseInt(b) - parseInt(a));

          this.year_options = years;

          this.is_loading_years = false;
      },
        async getSupplierOptions() {
            this.is_loading_suppliers = true;

            await this.$axios.get('suppliers/list')
                .then(({data}) => {
                    this.supplier_options = data.data;
                    this.is_loading_suppliers = false;
                })
                .catch(e => {
                    this.is_loading_suppliers = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_suppliers')),
                        type: 'error',
                    });
                });
        },
        async getPurchaserOptions() {
            this.is_loading_purchasers = true;

            await this.$axios.get('purchasers/list')
                .then(({data}) => {
                    this.purchaser_options = data.data;
                    this.is_loading_purchasers = false;
                })
                .catch(e => {
                    this.is_loading_purchasers = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_purchasers')),
                        type: 'error',
                    });
                });
        },
        async getCategoryOptions() {
            this.is_loading_categories = true;

            await this.$axios.get('categories/list')
                .then(({data}) => {
                    this.category_options = data.data;
                    this.is_loading_categories = false;
                })
                .catch(e => {
                    this.is_loading_categories = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_categories')),
                        type: 'error',
                    });
                });
        },
        async getLocationOptions() {
            this.is_loading_locations = true;

            await this.$axios.get('locations/list')
                .then(({data}) => {
                    this.location_options = data.data;
                    this.is_loading_locations = false;
                })
                .catch(e => {
                    this.is_loading_locations = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_locations')),
                        type: 'error',
                    });
                });
        },
        async getEmployeeOptions() {
            this.is_loading_employees = true;

            await this.$axios.get('employees/list')
                .then(({data}) => {
                    this.employee_options = data.data;
                    this.is_loading_employees = false;
                })
                .catch(e => {
                    this.is_loading_employees = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_employees')),
                        type: 'error',
                    });
                });
        },
        async getTypeOptions() {
            this.is_loading_types = true;

            await this.$axios.get(`categories/${this.original_item.relationships.category.data.id}/types/list`)
                .then(({data}) => {
                    this.type_options = data.data;
                    this.is_loading_types = false;
                })
                .catch(e => {
                    this.is_loading_types = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_types')),
                        type: 'error',
                    });
                });
        },
        async populate() {
            if (!this.original_item || !this.item)
                return;

            this.$v.item.description.$model = this.original_item.attributes.description;
            this.$v.item.found.$model = this.original_item.attributes.is_found ? 'Yes' : 'No';
            this.$v.item.unit_price.$model = this.original_item.attributes.unit_price;
            this.$v.item.financial_year.$model = this.original_item.attributes.financial_year;
            this.$v.item.order_number.$model = this.original_item.attributes.order_number;
            this.$v.item.invoice_date.$model = this.original_item.attributes.invoice_date;
            this.$v.item.sequence_number.$model = this.original_item.attributes.sequence_no;
            this.$v.item.invoice_number.$model = this.original_item.attributes.invoice_number;
            this.$v.item.supplier.$model = _.find(this.supplier_options, {id: this.original_item.relationships.supplier.data.id});
            this.$v.item.purchaser.$model = _.find(this.purchaser_options, {id: this.original_item.relationships.purchaser.data.id});
            this.$v.item.category.$model = _.find(this.category_options, {id: this.original_item.relationships.category.data.id});
            this.$v.item.category_number.$model = this.original_item.attributes.category_number;
            this.$v.item.location.$model = _.find(this.location_options, {id: this.original_item.relationships.location.data.id});
            this.$v.item.assigned_to.$model = _.find(this.employee_options, {id: this.original_item.relationships.employee.data.id});
            this.$v.item.type.$model = _.find(this.type_options, {id: this.original_item.relationships.type.data.id});

            if (this.original_item.attributes.serial_number)
                this.$v.item.serial_number.$model = this.original_item.attributes.serial_number;

            this.$v.item.notes.$model = this.original_item.attributes.note;
        },
        async calculateDepreciation() {
            if(!this.original_item) return;

            this.final_depreciation_values = [];
            this.total_depreciation_years = Math.ceil(100 / this.original_item.relationships.category.data.attributes.depreciation_value); //total years to depreciate
            this.total_depreciation_months = this.total_depreciation_years * 12; //total months to depreciate

            for(let i = 1; i <= this.total_depreciation_years; i++) {
                this.final_depreciation_values.push({
                    year: this.$moment(this.original_item.attributes.financial_year).add('years', i-1).format('YYYY'),
                    value: this.original_item.attributes.unit_price - (this.original_item.attributes.unit_price * this.original_item.relationships.category.data.attributes.depreciation_value / 100 * i) < 0 ? 0 : this.original_item.attributes.unit_price - (this.original_item.attributes.unit_price * this.original_item.relationships.category.data.attributes.depreciation_value / 100 * i)
                })
            }

            let years_passed = 0;

            if(this.currentYear >= this.final_depreciation_values[0].year) {
                years_passed = this.currentYear - this.final_depreciation_values[0].year + 1;

                this.current_total_depreciation = this.original_item.attributes.unit_price * this.original_item.relationships.category.data.attributes.depreciation_value / 100 * years_passed;
            }
        },
        async getAudits() {
            this.is_loading_audits = true;
            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            await this.$axios.get(`items/${this.$route.params.id}/audits`, {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.audits = data.data
                    this.totalRecordsAudits = data.meta.total;
                    this.is_loading_audits = false
                })
                .catch(e => {
                    this.is_loading_audits = false
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('items.error_retrieve_audits')),
                        type: 'error',
                    });
                });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getAudits();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.getAudits();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.getAudits();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        toggleWriteOff() {
            this.$modal.show(
                ItemsWriteOffModal, {},
                {
                    name: 'items-write-off-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getOriginalItem();
                    }
                }
            );
        },
        toggleDisposeOf() {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('items.dispose_of'),
                    message: this.$t('items.prompt_dispose_of'),
                    confirmText: this.$t('items.dispose'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.disposing = true;

                            let payload = {
                                disposed_of_at: this.$moment(new Date()).format('YYYY-MM-DD HH:mm'),
                                status: 'disposed_of'
                            }

                            this.$axios.patch(`items/${this.$route.params.id}`, payload).then(({data}) => {
                                this.disposing = false;

                                this.getOriginalItem();
                            }).catch(e => {
                                this.disposing = false;

                                this.$notify({
                                    title: this.$t('error'),
                                    text: this.$larerror(e.response.data.errors, this.$t('items.error_dispose_of')),
                                    type: 'error',
                                });
                            });
                        }
                    }
                }
            );
        },
        update() {
            this.$v.item.$touch();
            if (this.$v.item.$anyError || this.is_processing)
              return;

            this.is_saving = true;

            let payload = {
                description: this.item.description,
                is_found: this.item.found === 'Yes' ? 1 : 0,
                unit_price: this.item.unit_price,
                financial_year: this.$moment(this.item.financial_year).format('YYYY'),
                order_number: this.item.order_number,
                invoice_date: this.item.invoice_date,
                invoice_number: this.item.invoice_number,
                supplier_id: this.item.supplier.id,
                purchaser_id: this.item.purchaser.id,
                category_id: this.item.category.id,
                category_number: this.item.category_number,
                location_id: this.item.location.id,
                employee_id: this.item.assigned_to.id,
                type_id: this.item.type.id,
                serial_number: this.item.serial_number,
                sequence_no: this.item.sequence_number,
                note: this.item.notes,
            };

            this.$axios.patch(`items/${this.$route.params.id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('items.success_updated'),
                    type: 'success',
                });

                if(this.item.financial_year !== this.original_item.attributes.financial_year || this.item.unit_price !== this.original_item.attributes.unit_price) {
                    this.getOriginalItem();
                }

                this.getAudits();

                this.is_saving = false;
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('items.error_update')),
                    type: 'error',
                });
            });
        }
    },
    async mounted() {
        this.is_loading_original_item = true;

        await this.getOriginalItem();

        await this.getYearOptions();
        await this.getSupplierOptions();
        await this.getPurchaserOptions();
        await this.getLocationOptions();
        await this.getEmployeeOptions();
        await this.getCategoryOptions();
        await this.getTypeOptions();

        await this.getAudits();

        await this.populate();

        this.is_loading_original_item = false;
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.items')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-container {
        @apply flex flex-col;

        @screen lg {
            @apply flex-row;
        }
    }

    .headbar-wrapper {
        @apply flex flex-col;

        @screen md {
            @apply flex-row mt-4;
        }

        @screen lg {
            @apply mt-0;
        }

        .status-container {
            @apply flex flex-col my-4;

            @screen md {
                @apply flex-row my-auto;
            }

            & > * {
                @apply mb-2 mx-auto;

                @screen md {
                    @apply mr-4 my-auto;
                }
            }

            .status {
                @apply text-white px-3 py-1 rounded-lg text-sm capitalize;

                &.active {
                    @apply bg-positive;
                }

                &.completed {
                    @apply bg-yellow;
                }

                &.written-off {
                    @apply bg-neutral;
                }

                &.disposed-of {
                    @apply bg-negative;
                }
            }
        }

        .buttons-container {
            @apply flex flex-row flex-wrap mx-auto;

            & > * {
                @apply mr-2;
            }
        }
    }

    main {
        .main-container {
            @apply flex flex-col mb-8 pb-8 border-b-2 border-primary;

            @screen xl {
                @apply flex-row items-stretch;
            }

            .details-container {
                @apply w-full mb-8;

                @screen xl {
                    @apply w-3/5 mr-4 mb-0;
                }

                .form-body {
                    @apply px-8 pt-7 pb-2.5;
                }
            }

            .financial-breakdown-container {
                @apply w-full;

                @screen xl {
                    @apply w-2/5 ml-4;
                }

                .form-body {
                    @apply px-8 pt-7 pb-2.5 bg-white;

                    @screen xl {
                        @apply pb-8;
                        height: calc(100% - 52px)
                    }

                    .details-container {
                        @apply flex flex-col border-2 border-primary rounded p-4 w-full h-full;

                        @screen lg {
                            @apply p-6;
                        }

                        .details-row {
                            @apply flex flex-row flex-wrap justify-between gap-y-4 gap-x-2;

                            &.values-row {
                                @apply gap-y-8 gap-x-0 justify-start;

                                & > .detail-item {
                                    @apply w-1/5;
                                }
                            }

                            &.border-bottom {
                                @apply mb-8 pb-8 border-b-2 border-primary;
                            }

                            &:last-of-type {
                                @apply mt-8 justify-start gap-x-8;
                            }

                            .detail-item {
                                @apply flex flex-col;

                                .label {
                                    @apply font-bold text-sm mb-2;
                                }

                                .text {
                                    @apply text-sm;
                                }
                            }
                        }
                    }
                }
            }
        }

        .audits-container {
            @apply w-full;

            .title-container {
                @apply flex flex-row justify-between mb-4;

                h1 {
                    @apply text-black font-bold text-2xl;
                }
            }

            .not-available-container {
                @apply flex flex-row items-center gap-x-2;

                .not-available-circle {
                    @apply bg-black w-4 h-4 rounded-full;
                }
            }

            .td-after {
                @apply flex flex-row;

                & > * {
                    @apply mr-3;

                    &:last-child {
                        @apply mr-0;
                    }
                }
            }

            .row {
                &:first-of-type {
                    @apply mb-2;
                }

                &:last-of-type {
                    @apply mb-0;
                }
            }
        }
    }
}
</style>
