<template>
    <div class="types-create-modal">
        <ModalContainer :title="$t('types.add_type')" identifier="types-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_loading_categories || is_saving">
                <FormInputText v-model="$v.type.name.$model" identifier="name" :label="$t('types.name')"
                               :disabled="is_loading_categories || is_saving" :has-error="$v.type.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.type.name.required">
                            {{$t('validation.x_is_required',{x: $t('types.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputSelect v-model="$v.type.category.$model" identifier="role"
                                 :label="$t('types.category')" :options="categoryOptions"
                                 :disabled="is_loading_categories || is_saving" :has-error="$v.type.category.$error"
                                 track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
                    <template v-slot:errors>
                        <p v-if="!$v.type.category.required">
                            {{$t('validation.x_is_required',{x: $t('types.category')})}}
                        </p>
                    </template>
                </FormInputSelect>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "TypesCreateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        data() {
            return {
                type: {
                    name: null,
                    category: null,
                },
                categoryOptions: [],
                is_loading_categories: false,
                is_saving: false,
            }
        },
        validations: {
            type: {
                name: {required},
                category: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('types-create-modal', status);
            },
            save() {
                this.$v.type.$touch();
                if (this.$v.type.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = {
                    name: this.type.name,
                    category_id: this.type.category.id
                }

                this.$axios.post(`types`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('types.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('types.error_create')),
                        type: 'error',
                    });
                });
            },
            retrieveCategoryOptions() {
                this.is_loading_categories = true;

                this.$axios.get('categories/list')
                    .then(({data}) => {
                        this.categoryOptions = data.data;
                        this.is_loading_categories = false;
                    })
                    .catch(e => {
                        this.is_loading_categories = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                            type: 'error',
                        });
                    });
            }
        },
        mounted() {
            this.retrieveCategoryOptions();
        }
    }
</script>
