<template>
    <div class="categories-create-modal">
        <ModalContainer :title="$t('categories.add_category')" identifier="categories-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.category.code.$model" identifier="code" :label="$t('categories.code')"
                               :disabled="is_saving" :has-error="$v.category.code.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.category.code.required">
                            {{$t('validation.x_is_required',{x: $t('categories.code')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.category.name.$model" identifier="name" :label="$t('categories.name')"
                               :disabled="is_saving" :has-error="$v.category.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.category.name.required">
                            {{$t('validation.x_is_required',{x: $t('categories.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.category.depreciation_value.$model" identifier="name" :label="$t('categories.depreciation_value')"
                               :disabled="is_saving" :has-error="$v.category.depreciation_value.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.category.depreciation_value.required">
                            {{$t('validation.x_is_required',{x: $t('categories.depreciation_value')})}}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "CategoriesCreateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        data() {
            return {
                category: {
                    code: null,
                    name: null,
                    depreciation_value: null,
                },
                is_saving: false,
            }
        },
        validations: {
            category: {
                code: {required},
                name: {required},
                depreciation_value: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('categories-create-modal', status);
            },
            save() {
                this.$v.category.$touch();
                if (this.$v.category.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.category.$model);

                this.$axios.post(`categories`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('categories.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('categories.error_create')),
                        type: 'error',
                    });
                });
            },
        },
    }
</script>
