<template>
    <form @submit.prevent="$emit('submit')" class="form-container" :disabled="disabled">
        <slot/>
    </form>
</template>

<script>
    export default {
        name: "Form",
        props: {
            disabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-container {
        @apply flex flex-col bg-white rounded-b;

        button[type="submit"] {
            @apply self-end;
        }
    }
</style>